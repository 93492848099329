import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  Images,
  AgxSlideUpModal,
  DocumentTypes,
  StakeholderType,
  FormType,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { selectContract } from 'features/campaigns/campaignsReducer';
import { Tabs } from 'types/Tabs';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { setAndShowErrorToast } from 'store/config';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import useStateFormLabels from 'features/form/FormLabels/useStateFormLabels';
import { prepareSignatories } from 'utils/vendorBuyerUtil';
import { formatCurrency } from 'utils/formatNumber';
import { formatListOfStrings } from 'utils/formatString';
import { completeCampaignStep } from 'Api/Campaigns/campaignsApi';
import { useFormLoader } from 'hooks/useFormLoader';

const ResumeContractRequest = ({
  campaign,
  contract,
  currentTab,
  mobile = false,
}) => {
  const { address, campaignId } = campaign;
  const { contractId, lastUpdate, documents, buyers, purchasePrice } = contract;

  const lastUpdatedOn = `Last updated ${moment(lastUpdate).format('DD/MM/YY')}`;

  const allBuyers = prepareSignatories(buyers, 'Buyer');
  const allBuyerNames = allBuyers
    .map(item => {
      if (item.type === StakeholderType.Buyer) return item.name;
      if (item.type === StakeholderType.Attorney) return item.principalName;
      if (item.type === StakeholderType.Signatory) return item.companyName;

      return item;
    })
    .filter(name => name !== undefined);

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const dispatch = useDispatch();
  const [, getAuthToken] = useAzureAuth();
  const { loadForm, loadFormWithConfig } = useFormLoader();

  const availableForms = useSelector(state => state.forms.availableForms);

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const isMobile = ScreenSize() === Breakpoints.Mobile;

  const formName = useStateFormLabels(campaign.state, 'formName');

  const contractIsGenerated = documents?.some(
    c => c.documentType === DocumentTypes.SalesContract
  );

  if (!availableForms) return;

  const resumeContract = async () => {
    try {
      setDisableButtons(true);

      await loadForm(campaignId, contractId);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to resume Contract'));
    }

    setDisableButtons(false);
  };

  const beginContractSubmission = async () => {
    const contractSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ContractSubmission &&
        isEqual(form.state, campaign.state)
    );

    if (!contractSubmissionForm) return;

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();
      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        true,
        contract.contractId
      );

      if (!newForm) {
        dispatch(setAndShowErrorToast('Failed to start Contract submission'));
        setDisableButtons(false);
        return;
      }

      await loadFormWithConfig(newForm);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to start Contract submission'));
    }

    setDisableButtons(false);
  };

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>Starting a submission?</AgxHeader>
      <AgxBodyText medium>
        You will not be able to edit the {formName} once you continue.
      </AgxBodyText>
      <AgxBodyText medium>Continue to submission?</AgxBodyText>
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="startsubmission"
          large
          primary
          text="Yes, start submission"
          onClick={beginContractSubmission}
          disabled={
            disableButtons ||
            (contract?.inProgressForm != null && currentTab === Tabs.Archive)
          }
        />
        <AgxButton
          id="cancel"
          hollow
          large
          text="No, go back"
          onClick={() => setShowConfirmationModel(false)}
          disabled={disableButtons}
        />
      </div>
    </AgxColumn>
  );

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={lastUpdatedOn}
        onBack={() => dispatch(selectContract(null))}
      />
      <AgxColumn centered veryLargeGap extraClasses="resumeFormDetails">
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxBodyText medium centered>
          {formatListOfStrings(allBuyerNames)} •{' '}
          {formatCurrency(purchasePrice, 0)}
        </AgxBodyText>
        <Images.SparkleForm />
        <AgxColumn mediumGap centered>
          {!contractIsGenerated && (
            <AgxBodyText small centered>
              There are more fields to fill
            </AgxBodyText>
          )}
        </AgxColumn>
      </AgxColumn>
      <div className="campaignModalButtons">
        <AgxButton
          hollow={!mobile}
          primary={mobile}
          wide={mobile}
          large
          text="Resume"
          onClick={resumeContract}
          shrinkOnLargerDevices
          disabled={disableButtons}
        />
        {!!contract.envelopeSubmission && contractIsGenerated && (
          <AgxButton
            primary
            wide={mobile}
            large
            disabled={disableButtons || currentTab === Tabs.Archive}
            text="Start Submission"
            onClick={() => setShowConfirmationModel(true)}
            shrinkOnLargerDevices
          />
        )}
      </div>
      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </>
  );
};

export default ResumeContractRequest;
