import {
  AgxButton,
  AgxColumn,
  AgxLabel,
  AgxRow,
  MultiOptionButtonOption,
} from '@urbanx/agx-ui-components';
import slugify from 'slugify';
import './FileList.scss';

interface Props {
  files: MultiOptionButtonOption[];
  extraClasses?: string;
}
const FileList = (props: Props) => {
  const { files, extraClasses = '' } = props;

  if (files.length === 0) <></>;

  return (
    <AgxColumn mediumGap fill centered>
      <AgxColumn mediumGap fill extraClasses={'fileListItemWithoutBorder'}>
        <AgxRow fill>
          <AgxLabel medium>Documents</AgxLabel>
        </AgxRow>
        {files.map((file, index) => (
          <AgxRow
            fill
            key={`${file.text}-${index}`}
            spaceBetween
            extraClasses={`fileListItem ${extraClasses} `}
          >
            <AgxLabel medium>{file.text}</AgxLabel>
            <AgxButton
              id={`viewContractSubmissionPdfs-download-${slugify(file.text)}`}
              naked
              text="Download"
              extraClasses="downloadButtonStyle"
              onClick={() => (file.onClick ? file.onClick() : undefined)}
            />
          </AgxRow>
        ))}
      </AgxColumn>
    </AgxColumn>
  );
};

export default FileList;
