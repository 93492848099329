import { useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  AgxColumn,
  AgxHeader,
  AgxBodyText,
  DocumentTypes,
  DocumentTypesMap,
  AgxRow,
  AgxLabel,
  SPECIAL_CHARACTERS,
} from '@urbanx/agx-ui-components';
import placeHolderImage from 'assets/images/placeholder-property.png';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { prepareSignatories } from 'utils/vendorBuyerUtil';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { formatListOfStrings } from 'utils/formatString';
import { formatCurrency } from 'utils/formatNumber';
import { useSelectedContract } from 'hooks/useSelectedContract';
import { selectContract } from '../../../../../../campaigns/campaignsReducer';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import { sortDocumentsByType } from 'utils/documentUtil';
import FileList from '../Common/FileList';

const ContractSubmitPanel = ({ campaign, contract, showDownload = true }) => {
  const dispatch = useDispatch();
  const { lastUpdate, buyers, documents, purchasePrice } =
    useSelectedContract();
  const { address, propertyImageUrls: { medium: imageUrl = null } = {} } =
    campaign;

  const headerDetail = `Submitted ${moment(lastUpdate).format('DD/MM/YY')}`;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);
  const isMobile = ScreenSize() === Breakpoints.Mobile;

  const allBuyers = prepareSignatories(buyers, 'Buyer');

  const getFileLink = useGetFileLink();

  const allBuyerNames = allBuyers
    .map(item => {
      if (item.type === 'Buyer') return item.name;
      if (item.type === 'Attorney') return item.principalName;
      if (item.type === 'Signatory') return item.companyName;

      return item;
    })
    .filter(name => name !== undefined);

  const fileDownloadOptions = useMemo(() => {
    if (documents?.length === 0) return [];
    const options = [];

    if (documents?.length > 0) {
      documents?.forEach(document => {
        if (document.documentType !== DocumentTypes.SalesContract) {
          options.push({
            text: DocumentTypesMap[document.documentType],
            documentType: document.documentType,
            onClick: async () => {
              const fileLink = await getFileLink(document.containerFilePath);
              window.open(fileLink);
            },
          });
        }
      });
    }

    // return unique document list by text property
    const uniqueDocumentList = [
      ...new Map(options.map(item => [item.text, item])).values(),
    ];

    return [...sortDocumentsByType(uniqueDocumentList)];
  }, [documents, getFileLink]);

  const ViewContractButton = () => {
    if (!documents || documents.length === 0 || !showDownload) return <></>;
    return (
      <FileList id="viewContractSubmissionPdfs" files={fileDownloadOptions} />
    );
  };

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={headerDetail}
        onBack={() => dispatch(selectContract(null))}
      />

      <AgxColumn veryLargeGap fill centered>
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxBodyText medium centered>
          {formatListOfStrings(allBuyerNames)} •{' '}
          {formatCurrency(purchasePrice, 0)}
        </AgxBodyText>
        <AgxRow fill centered justifyCenter extraClasses={'listing-imageCard'}>
          <div
            style={{
              backgroundImage: `url("${imageUrl || placeHolderImage}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: '2/1',
            }}
            className={`imageCard-image`}
          >
            <div className="image-tag">
              <AgxLabel medium colour="brand-text-button-color">
                {contract.contractStage.split(SPECIAL_CHARACTERS.BULLET)[1] ??
                  contract.contractStage}
              </AgxLabel>
            </div>
          </div>
        </AgxRow>
        <ViewContractButton />
      </AgxColumn>
    </>
  );
};

export default ContractSubmitPanel;
