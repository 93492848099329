import {
  AgxButton,
  AgxCaption,
  AgxColumn,
  AgxLabel,
  AgxRow,
  Images,
} from '@urbanx/agx-ui-components';
import { PreparedSignatory } from 'utils/vendorBuyerUtil';

interface SendToVendorsConfirmationProps {
  vendors?: PreparedSignatory[];
  onBack: () => void;
  onSend: () => void;
  disabled?: boolean;
}

const SendToVendorsConfirmation: React.FC<SendToVendorsConfirmationProps> = ({
  vendors = [],
  onBack,
  onSend,
  disabled = false,
}) => {
  return (
    <AgxColumn extraLargeGap>
      <AgxColumn fill extraClasses="signatory-row borderTop">
        {vendors &&
          vendors.length > 0 &&
          vendors.map((signatory, index) => {
            const signatoryName =
              signatory.name ?? `${signatory.firstName} ${signatory.lastName}`;
            return (
              <AgxRow
                key={`${signatory.id ?? signatory.id}-${index}`}
                veryLargeGap
                spaceBetween
                extraClasses="borderBottom signatory-content"
              >
                <AgxColumn extraLargePadding>
                  <AgxLabel>{signatoryName}</AgxLabel>
                  <AgxLabel medium colour="neutral-grey-700">
                    {signatory.email}
                  </AgxLabel>
                </AgxColumn>
                <AgxColumn centerJustified>
                  <AgxCaption colour="neutral-grey-700">{`Vendor ${index + 1}`}</AgxCaption>
                </AgxColumn>
              </AgxRow>
            );
          })}
      </AgxColumn>
      <AgxColumn extraLargePadding extraClasses="signatory-row">
        <AgxRow justifyCenter largeGap>
          <AgxButton
            id="startsubmission"
            large
            hollow
            text="Back"
            onClick={() => onBack()}
            disabled={disabled}
          />
          <AgxButton
            id="cancel"
            primary
            large
            text="Send"
            onClick={() => onSend()}
            disabled={disabled}
          />
        </AgxRow>
      </AgxColumn>
    </AgxColumn>
  );
};

export default SendToVendorsConfirmation;
